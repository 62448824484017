import React from "react";
import { graphql } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../../components/Templates/Layout";
import Meta from "../../utils/Meta";

import Hero from "../../components/Templates/Hero";

import { contactActions } from "../../store/contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../../components/Global/Link";

// Markup
const LinkContactSupportPage = (props) => {
  const { data, blok } = props;

  const dispatch = useDispatch();

  let doc;
  if (data) {
    doc = JSON.parse(data?.contact?.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
  };

  const isPurchasingMachines = useSelector(
    (state) => state.contact.isPurchasingMachines
  );
  const isPurchasingPrizes = useSelector(
    (state) => state.contact.isPurchasingPrizes
  );
  const isTalkingManagement = useSelector(
    (state) => state.contact.isTalkingManagement
  );
  const isTalkingTech = useSelector(
    (state) => state.contact.isTalkingTech
  );
  const isTalkingSpares = useSelector(
    (state) => state.contact.isTalkingSpares
  );

  const purchasingMachinesHandler = () => {
    dispatch(contactActions.setIsPurchasingMachines(!isPurchasingMachines));
    // navigate("/contact/form");
  };

  const purchasingPrizesHandler = () => {
    dispatch(contactActions.setIsPurchasingPrizes(!isPurchasingPrizes));
    // navigate("/contact/form");
  };

  const managementSolutionsHandler = () => {
    dispatch(contactActions.setIsTalkingManagement(!isTalkingManagement));
    // navigate("/contact/form");
  };

  const techHandler = () => {
    dispatch(contactActions.setIsTalkingTech(!isTalkingTech));
    // navigate("/contact/form");
  };

  const sparesHandler = () => {
    dispatch(contactActions.setIsTalkingSpares(!isTalkingSpares));
    // navigate("/contact/form");
  };

  return (
    <Layout>
      <Meta
        openGraph={openGraph}
        meta={doc.meta}
        colour={doc.colour && doc.colour}
      />

      <React.Fragment>
        <Hero title={doc.title} background={doc.image?.filename} />
        <section className="w-100 py-5 brand-background-white brand-text-black brand-background-clip border">
          <div className="container py-5">
            <div className="row mb-5">
              <div className="col-12">
              <h1 className="brand-font text-uppercase text-center brand-text-purple">
                How can we help?
              </h1>
              </div>
            </div>
            <div className="row justify-content-center gy-4">
              <div className="col col-12 col-md-4">
                <div
                  className={`d-flex flex-column brand-border-radius overflow-hidden h-100 brand-cursor py-5 ${
                    isTalkingTech
                      ? "brand-border-pink"
                      : "brand-border-grey"
                  }`}
                  onClick={techHandler}
                  onKeyDown={techHandler}
                  role="checkbox"
                  tabIndex={0}
                  aria-checked={isTalkingTech}
                >
                  <div
                    className={`text-center mb-5 ${
                      isTalkingTech ? "brand-text-pink" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={isTalkingTech ? "circle-check" : "circle"}
                      className="fs-1"
                    />
                  </div>
                  <div className="d-flex flex-column h-100 px-4 text-center">
                    <h5>
                        I'd like to talk to somebody about technical support
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col col-12 col-md-4">
                <div
                  className={`d-flex flex-column brand-border-radius overflow-hidden h-100 brand-cursor py-5 ${
                    isTalkingSpares
                      ? "brand-border-pink"
                      : "brand-border-grey"
                  }`}
                  onClick={sparesHandler}
                  onKeyDown={sparesHandler}
                  role="checkbox"
                  tabIndex={0}
                  aria-checked={isTalkingSpares}
                >
                  <div
                    className={`text-center mb-5 ${
                      isTalkingSpares ? "brand-text-pink" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={isTalkingSpares ? "circle-check" : "circle"}
                      className="fs-1"
                    />
                  </div>
                  <div className="d-flex flex-column h-100 px-4 text-center">
                    <h5>
                        Something is broken and I think I might need some spare parts!
                    </h5>
                  </div>
                </div>
              </div>

            </div>

            <div className="row justify-content-center py-5">
              <div className="d-flex justify-content-center align-items-center gap-4">
                <Link button icon="chevron-left" iconPosition="start" colour="pink" to="/contact/existing">
                  Back
                </Link>
                {(isTalkingTech || isTalkingSpares)?
                  <Link button icon colour="pink" to="/contact/form">
                    Next
                  </Link>
                :
                  <Link button icon colour="grey">
                    Next
                  </Link>
                }
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  );
};

export default LinkContactSupportPage;

export const QUERY = graphql`
  query LinkContactSupportPageQuery {
    contact: storyblokEntry(slug: { eq: "contact" }) {
      content
    }
    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;
